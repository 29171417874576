import axios from 'axios';


export enum MenuItem {
    BACON_EGG_CHEESE_BISCUIT = "Bacon Egg & Cheese Biscuit",
    EGG_MCMUFFIN = "Egg McMuffin®",
    SAUSAGE_MCMUFFIN = "Sausage McMuffin®",
    SAUSAGE_MCMUFFIN_WITH_EGG = "Sausage McMuffin® with Egg",
    SAUSAGE_BISCUIT = "Sausage Biscuit",
    SAUSAGE_BISCUIT_WITH_EGG = "Sausage Biscuit with Egg",
    BACON_EGG_AND_CHEESE_MCGRIDDLES = "Bacon, Egg & Cheese McGriddles®",
    SAUSAGE_MCGRIDDLES = "Sausage McGriddles®",
    SAUSAGE_EGG_AND_CHEESE_MCGRIDDLES = "Sausage, Egg & Cheese McGriddles®",
    BIG_BREAKFAST = "Big Breakfast®",
    BIG_BREAKFAST_WITH_HOTCAKES = "Big Breakfast® with Hotcakes",
    HOTCAKES = "Hotcakes",
    HOTCAKES_AND_SAUSAGE = "Hotcakes and Sausage",
    SAUSAGE_BURRITO = "Sausage Burrito",
    HASH_BROWNS = "Hash Browns",
    FRUIT_AND_MAPLE_OATMEAL = "Fruit & Maple Oatmeal",
    EGG_MCMUFFIN_MEAL = "Egg McMuffin® Meal",
    SAUSAGE_MCMUFFIN_WITH_EGG_MEAL = "Sausage McMuffin® with Egg Meal",
    SAUSAGE_BISCUIT_WITH_EGG_MEAL = "Sausage Biscuit with Egg Meal",
    BACON_EGG_AND_CHEESE_BISCUIT_MEAL = "Bacon, Egg & Cheese Biscuit Meal",
    BACON_EGG_AND_CHEESE_MCGRIDDLES_MEAL = "Bacon, Egg & Cheese McGriddles® Meal",
    SAUSAGE_EGG_AND_CHEESE_MCGRIDDLES_MEAL = "Sausage, Egg & Cheese McGriddles® Meal",
    SAUSAGE_MCGRIDDLES_MEAL = "Sausage McGriddles® Meal",
    SAUSAGE_BURRITO_MEAL = "Sausage Burrito Meal",
    BIG_MAC = "Big Mac®",
    QUARTER_POUNDER_WITH_CHEESE = "Quarter Pounder®* with Cheese",
    DOUBLE_QUARTER_POUNDER_WITH_CHEESE = "Double Quarter Pounder®* with Cheese",
    QUARTER_POUNDER_WITH_CHEESE_DELUXE = "Quarter Pounder®* with Cheese Deluxe",
    MCDOUBLE = "McDouble®",
    QUARTER_POUNDER_WITH_CHEESE_BACON = "Quarter Pounder®* with Cheese Bacon",
    CHEESEBURGER = "Cheeseburger",
    DOUBLE_CHEESEBURGER = "Double Cheeseburger",
    HAMBURGER_THE_CLASSIC_MCDONALDS_BURGER = "Hamburger: The Classic McDonald's Burger",
    MCCRISPY = "McCrispy™",
    DELUXE_MCCRISPY = "Deluxe McCrispy™",
    SPICY_MCCRISPY = "Spicy McCrispy™",
    SPICY_DELUXE_MCCRISPY = "Spicy Deluxe McCrispy™",
    FILET_O_FISH = "Filet-O-Fish®",
    MCCHICKEN = "McChicken®",
    CHICKEN_MCNUGGETS = "Chicken McNuggets®",
    TEN_PIECE_CHICKEN_MCNUGGETS = "10 Piece Chicken McNuggets®",
    WORLD_FAMOUS_FRIES = "World Famous Fries®",
    APPLE_SLICES = "Apple Slices",
    TANGY_BARBEQUE_SAUCE = "Tangy Barbeque Sauce",
    SPICY_BUFFALO_SAUCE = "Spicy Buffalo Sauce",
    CREAMY_RANCH_SAUCE = "Creamy Ranch Sauce",
    HONEY_MUSTARD_SAUCE = "Honey Mustard Sauce",
    HONEY = "Honey",
    SWEET_N_SOUR_SAUCE = "Sweet 'N Sour Sauce",
    KETCHUP_PACKET = "Ketchup Packet",
    MUSTARD_PACKET = "Mustard Packet",
    MAYONNAISE_PACKET = "Mayonnaise Packet",
    HAMBURGER_HAPPY_MEAL = "Hamburger Happy Meal®",
    FOUR_PIECE_CHICKEN_MCNUGGETS_HAPPY_MEAL = "4 Piece Chicken McNuggets® Happy Meal®",
    SIX_PIECE_CHICKEN_MCNUGGETS_HAPPY_MEAL = "6 Piece Chicken McNuggets® Happy Meal®",
    CARAMEL_MACCHIATO = "Caramel Macchiato",
    CAPPUCCINO = "Cappuccino",
    CARAMEL_CAPPUCCINO = "Caramel Cappuccino",
    FRENCH_VANILLA_CAPPUCCINO = "French Vanilla Cappuccino",
    MOCHA_LATTE = "Mocha Latte",
    AMERICANO = "Americano",
    PREMIUM_ROAST_COFFEE = "Premium Roast Coffee",
    ICED_CARAMEL_MACCHIATO = "Iced Caramel Macchiato",
    ICED_MOCHA = "Iced Mocha",
    ICED_COFFEE = "Iced Coffee",
    ICED_CARAMEL_COFFEE = "Iced Caramel Coffee",
    ICED_FRENCH_VANILLA_COFFEE = "Iced French Vanilla Coffee",
    LATTE = "Latte",
    ICED_LATTE = "Iced Latte",
    CARAMEL_LATTE = "Caramel Latte",
    ICED_CARAMEL_LATTE = "Iced Caramel Latte",
    FRENCH_VANILLA_LATTE = "French Vanilla Latte",
    ICED_FRENCH_VANILLA_LATTE = "Iced French Vanilla Latte",
    CARAMEL_FRAPPE = "Caramel Frappé",
    MOCHA_FRAPPE = "Mocha Frappé",
    MCFLURRY_WITH_OREO_COOKIES = "McFlurry® with OREO® Cookies",
    MCFLURRY_WITH_M_AND_MS_CANDIES = "McFlurry® with M&M'S® Candies",
    VANILLA_CONE = "Vanilla Cone",
    CHOCOLATE_SHAKE = "Chocolate Shake",
    VANILLA_SHAKE = "Vanilla Shake",
    STRAWBERRY_SHAKE = "Strawberry Shake",
    HOT_FUDGE_SUNDAE = "Hot Fudge Sundae",
    HOT_CARAMEL_SUNDAE = "Hot Caramel Sundae",
    BAKED_APPLE_PIE = "Baked Apple Pie",
    CHOCOLATE_CHIP_COOKIE = "Chocolate Chip Cookie",
    COCA_COLA = "Coca-Cola®",
    SPRITE = "Sprite®",
    DR_PEPPER = "Dr Pepper®",
    FANTA_ORANGE = "Fanta® Orange",
    DIET_COKE = "Diet Coke®",
    HI_C_ORANGE_LAVABURST = "Hi-C® Orange Lavaburst®",
    FROZEN_FANTA_BLUE_RASPBERRY = "Frozen Fanta® Blue Raspberry",
    FROZEN_COCACOLA_CLASSIC = "Frozen Coca-Cola® Classic",
    STRAWBERRY_BANANA_SMOOTHIE = "Strawberry Banana Smoothie",
    MANGO_PINEAPPLE_SMOOTHIE = "Mango Pineapple Smoothie",
    LEMONADE = "Lemonade",
    SWEET_TEA = "Sweet Tea",
    UNSWEETENED_ICED_TEA = "Unsweetened Iced Tea",
    HOT_TEA = "Hot Tea",
    MINUTE_MAID_PREMIUM_ORANGE_JUICE = "Minute Maid® Premium Orange Juice",
    KIDS_APPLEY_EVER_AFTER_ORGANIC_JUICE_DRINK = "Kids Appley Ever After® Organic Juice Drink",
    MILK = "Milk",
    CHOCOLATE_MILK = "Chocolate Milk",
    HOT_CHOCOLATE = "Hot Chocolate",
    WATER = "Water",
}


const toMenuItem = (item: string): MenuItem | undefined => {
    switch (item) {
        case "Bacon Egg & Cheese Biscuit": return MenuItem.BACON_EGG_CHEESE_BISCUIT;
        case "Egg McMuffin®": return MenuItem.EGG_MCMUFFIN;
        case "Sausage McMuffin®": return MenuItem.SAUSAGE_MCMUFFIN;
        case "Sausage McMuffin® with Egg": return MenuItem.SAUSAGE_MCMUFFIN_WITH_EGG;
        case "Sausage Biscuit": return MenuItem.SAUSAGE_BISCUIT;
        case "Sausage Biscuit with Egg": return MenuItem.SAUSAGE_BISCUIT_WITH_EGG;
        case "Bacon, Egg & Cheese McGriddles®": return MenuItem.BACON_EGG_AND_CHEESE_MCGRIDDLES;
        case "Sausage McGriddles®": return MenuItem.SAUSAGE_MCGRIDDLES;
        case "Sausage, Egg & Cheese McGriddles®": return MenuItem.SAUSAGE_EGG_AND_CHEESE_MCGRIDDLES;
        case "Big Breakfast®": return MenuItem.BIG_BREAKFAST;
        case "Big Breakfast® with Hotcakes": return MenuItem.BIG_BREAKFAST_WITH_HOTCAKES;
        case "Hotcakes": return MenuItem.HOTCAKES;
        case "Hotcakes and Sausage": return MenuItem.HOTCAKES_AND_SAUSAGE;
        case "Sausage Burrito": return MenuItem.SAUSAGE_BURRITO;
        case "Hash Browns": return MenuItem.HASH_BROWNS;
        case "Fruit & Maple Oatmeal": return MenuItem.FRUIT_AND_MAPLE_OATMEAL;
        case "Egg McMuffin® Meal": return MenuItem.EGG_MCMUFFIN_MEAL;
        case "Sausage McMuffin® with Egg Meal": return MenuItem.SAUSAGE_MCMUFFIN_WITH_EGG_MEAL;
        case "Sausage Biscuit with Egg Meal": return MenuItem.SAUSAGE_BISCUIT_WITH_EGG_MEAL;
        case "Bacon, Egg & Cheese Biscuit Meal": return MenuItem.BACON_EGG_AND_CHEESE_BISCUIT_MEAL;
        case "Bacon, Egg & Cheese McGriddles® Meal": return MenuItem.BACON_EGG_AND_CHEESE_MCGRIDDLES_MEAL;
        case "Sausage, Egg & Cheese McGriddles® Meal": return MenuItem.SAUSAGE_EGG_AND_CHEESE_MCGRIDDLES_MEAL;
        case "Sausage McGriddles® Meal": return MenuItem.SAUSAGE_MCGRIDDLES_MEAL;
        case "Sausage Burrito Meal": return MenuItem.SAUSAGE_BURRITO_MEAL;
        case "Big Mac®": return MenuItem.BIG_MAC;
        case "Quarter Pounder®* with Cheese": return MenuItem.QUARTER_POUNDER_WITH_CHEESE;
        case "Double Quarter Pounder®* with Cheese": return MenuItem.DOUBLE_QUARTER_POUNDER_WITH_CHEESE;
        case "Quarter Pounder®* with Cheese Deluxe": return MenuItem.QUARTER_POUNDER_WITH_CHEESE_DELUXE;
        case "McDouble®": return MenuItem.MCDOUBLE;
        case "Quarter Pounder®* with Cheese Bacon": return MenuItem.QUARTER_POUNDER_WITH_CHEESE_BACON;
        case "Cheeseburger": return MenuItem.CHEESEBURGER;
        case "Double Cheeseburger": return MenuItem.DOUBLE_CHEESEBURGER;
        case "Hamburger: The Classic McDonald's Burger": return MenuItem.HAMBURGER_THE_CLASSIC_MCDONALDS_BURGER;
        case "McCrispy™": return MenuItem.MCCRISPY;
        case "Deluxe McCrispy™": return MenuItem.DELUXE_MCCRISPY;
        case "Spicy McCrispy™": return MenuItem.SPICY_MCCRISPY;
        case "Spicy Deluxe McCrispy™": return MenuItem.SPICY_DELUXE_MCCRISPY;
        case "Filet-O-Fish®": return MenuItem.FILET_O_FISH;
        case "McChicken®": return MenuItem.MCCHICKEN;
        case "Chicken McNuggets®": return MenuItem.CHICKEN_MCNUGGETS;
        case "10 Piece Chicken McNuggets®": return MenuItem.TEN_PIECE_CHICKEN_MCNUGGETS;
        case "World Famous Fries®": return MenuItem.WORLD_FAMOUS_FRIES;
        case "Apple Slices": return MenuItem.APPLE_SLICES;
        case "Tangy Barbeque Sauce": return MenuItem.TANGY_BARBEQUE_SAUCE;
        case "Spicy Buffalo Sauce": return MenuItem.SPICY_BUFFALO_SAUCE;
        case "Creamy Ranch Sauce": return MenuItem.CREAMY_RANCH_SAUCE;
        case "Honey Mustard Sauce": return MenuItem.HONEY_MUSTARD_SAUCE;
        case "Honey": return MenuItem.HONEY;
        case "Sweet 'N Sour Sauce": return MenuItem.SWEET_N_SOUR_SAUCE;
        case "Ketchup Packet": return MenuItem.KETCHUP_PACKET;
        case "Mustard Packet": return MenuItem.MUSTARD_PACKET;
        case "Mayonnaise Packet": return MenuItem.MAYONNAISE_PACKET;
        case "Hamburger Happy Meal®": return MenuItem.HAMBURGER_HAPPY_MEAL;
        case "4 Piece Chicken McNuggets® Happy Meal®": return MenuItem.FOUR_PIECE_CHICKEN_MCNUGGETS_HAPPY_MEAL;
        case "6 Piece Chicken McNuggets® Happy Meal®": return MenuItem.SIX_PIECE_CHICKEN_MCNUGGETS_HAPPY_MEAL;
        case "Caramel Macchiato": return MenuItem.CARAMEL_MACCHIATO;
        case "Cappuccino": return MenuItem.CAPPUCCINO;
        case "Caramel Cappuccino": return MenuItem.CARAMEL_CAPPUCCINO;
        case "French Vanilla Cappuccino": return MenuItem.FRENCH_VANILLA_CAPPUCCINO;
        case "Mocha Latte": return MenuItem.MOCHA_LATTE;
        case "Americano": return MenuItem.AMERICANO;
        case "Premium Roast Coffee": return MenuItem.PREMIUM_ROAST_COFFEE;
        case "Iced Caramel Macchiato": return MenuItem.ICED_CARAMEL_MACCHIATO;
        case "Iced Mocha": return MenuItem.ICED_MOCHA;
        case "Iced Coffee": return MenuItem.ICED_COFFEE;
        case "Iced Caramel Coffee": return MenuItem.ICED_CARAMEL_COFFEE;
        case "Iced French Vanilla Coffee": return MenuItem.ICED_FRENCH_VANILLA_COFFEE;
        case "Latte": return MenuItem.LATTE;
        case "Iced Latte": return MenuItem.ICED_LATTE;
        case "Caramel Latte": return MenuItem.CARAMEL_LATTE;
        case "Iced Caramel Latte": return MenuItem.ICED_CARAMEL_LATTE;
        case "French Vanilla Latte": return MenuItem.FRENCH_VANILLA_LATTE;
        case "Iced French Vanilla Latte": return MenuItem.ICED_FRENCH_VANILLA_LATTE;
        case "Caramel Frappé": return MenuItem.CARAMEL_FRAPPE;
        case "Mocha Frappé": return MenuItem.MOCHA_FRAPPE;
        case "McFlurry® with OREO® Cookies": return MenuItem.MCFLURRY_WITH_OREO_COOKIES;
        case "McFlurry® with M&M'S® Candies": return MenuItem.MCFLURRY_WITH_M_AND_MS_CANDIES;
        case "Vanilla Cone": return MenuItem.VANILLA_CONE;
        case "Chocolate Shake": return MenuItem.CHOCOLATE_SHAKE;
        case "Vanilla Shake": return MenuItem.VANILLA_SHAKE;
        case "Strawberry Shake": return MenuItem.STRAWBERRY_SHAKE;
        case "Hot Fudge Sundae": return MenuItem.HOT_FUDGE_SUNDAE;
        case "Hot Caramel Sundae": return MenuItem.HOT_CARAMEL_SUNDAE;
        case "Baked Apple Pie": return MenuItem.BAKED_APPLE_PIE;
        case "Chocolate Chip Cookie": return MenuItem.CHOCOLATE_CHIP_COOKIE;
        case "Coca-Cola®": return MenuItem.COCA_COLA;
        case "Sprite®": return MenuItem.SPRITE;
        case "Dr Pepper®": return MenuItem.DR_PEPPER;
        case "Fanta® Orange": return MenuItem.FANTA_ORANGE;
        case "Diet Coke®": return MenuItem.DIET_COKE;
        case "Hi-C® Orange Lavaburst®": return MenuItem.HI_C_ORANGE_LAVABURST;
        case "Frozen Fanta® Blue Raspberry": return MenuItem.FROZEN_FANTA_BLUE_RASPBERRY;
        case "Frozen Coca-Cola® Classic": return MenuItem.FROZEN_COCACOLA_CLASSIC;
        case "Strawberry Banana Smoothie": return MenuItem.STRAWBERRY_BANANA_SMOOTHIE;
        case "Mango Pineapple Smoothie": return MenuItem.MANGO_PINEAPPLE_SMOOTHIE;
        case "Lemonade": return MenuItem.LEMONADE;
        case "Sweet Tea": return MenuItem.SWEET_TEA;
        case "Unsweetened Iced Tea": return MenuItem.UNSWEETENED_ICED_TEA;
        case "Hot Tea": return MenuItem.HOT_TEA;
        case "Minute Maid® Premium Orange Juice": return MenuItem.MINUTE_MAID_PREMIUM_ORANGE_JUICE;
        case "Kids Appley Ever After® Organic Juice Drink": return MenuItem.KIDS_APPLEY_EVER_AFTER_ORGANIC_JUICE_DRINK;
        case "Milk": return MenuItem.MILK;
        case "Chocolate Milk": return MenuItem.CHOCOLATE_MILK;
        case "Hot Chocolate": return MenuItem.HOT_CHOCOLATE;
        case "Water": return MenuItem.WATER;
        default: return undefined;
    }
};


export function menuItemToNameAndImage(item: MenuItem): [string, string] | undefined {
    switch (item) {
        case MenuItem.BACON_EGG_CHEESE_BISCUIT: return ["Bacon Egg & Cheese Biscuit", "mcdonalds/DC_202211_0085_BaconEggCheeseBiscuit_832x472.jpeg"];
        case MenuItem.EGG_MCMUFFIN: return ["Egg McMuffin®", "mcdonalds/DC_202004_0046_EggMcMuffin_832x472.jpeg"];
        case MenuItem.SAUSAGE_MCMUFFIN: return ["Sausage McMuffin®", "mcdonalds/DC_202002_0078_SausageMcMuffin_832x472.jpeg"];
        case MenuItem.SAUSAGE_MCMUFFIN_WITH_EGG: return ["Sausage McMuffin® with Egg", "mcdonalds/DC_201907_0083_SausageEggMcMuffin_832x472.jpeg"];
        case MenuItem.SAUSAGE_BISCUIT: return ["Sausage Biscuit", "mcdonalds/DC_201907_0062_SausageBiscuit_832x472.jpeg"];
        case MenuItem.SAUSAGE_BISCUIT_WITH_EGG: return ["Sausage Biscuit with Egg", "mcdonalds/DC_201907_0092_SausageEggBiscuit_832x472.jpeg"];
        case MenuItem.BACON_EGG_AND_CHEESE_MCGRIDDLES: return ["Bacon, Egg & Cheese McGriddles®", "mcdonalds/DC_202008_9840_EVM_HB_BaconEggCheeseMcGriddle_Coffee_Glass_832x472.jpeg"];
        case MenuItem.SAUSAGE_MCGRIDDLES: return ["Sausage McGriddles®", "mcdonalds/DC_201911_6110_SausageMcGriddle_832x472.jpeg"];
        case MenuItem.SAUSAGE_EGG_AND_CHEESE_MCGRIDDLES: return ["Sausage, Egg & Cheese McGriddles®", "mcdonalds/DC_201907_9841_SausageEggCheeseMcGriddle_832x472.jpeg"];
        case MenuItem.BIG_BREAKFAST: return ["Big Breakfast®", "mcdonalds/DC_202004_0107_BigBreakfast_832x472.jpeg"];
        case MenuItem.BIG_BREAKFAST_WITH_HOTCAKES: return ["Big Breakfast® with Hotcakes", "mcdonalds/DC_202104_3590_BigBreakfast_HotCakes_832x472.jpeg"];
        case MenuItem.HOTCAKES: return ["Hotcakes", "mcdonalds/DC_202208_0031_3HotCakes_832x472.jpeg"];
        case MenuItem.HOTCAKES_AND_SAUSAGE: return ["Hotcakes and Sausage", "mcdonalds/DC_202208_0032_3HotCakes_Sausage_832x472.jpeg"];
        case MenuItem.SAUSAGE_BURRITO: return ["Sausage Burrito", "mcdonalds/DC_202004_0334_SausageBurrito_Alt_832x472.jpeg"];
        case MenuItem.HASH_BROWNS: return ["Hash Browns", "mcdonalds/DC_202004_0035_HashBrowns_Broken_832x472.jpeg"];
        case MenuItem.FRUIT_AND_MAPLE_OATMEAL: return ["Fruit & Maple Oatmeal", "mcdonalds/DC_Ingredient_Condiment_202203_00046-048__9004_Mustard_832x472.jpeg"];
        case MenuItem.EGG_MCMUFFIN_MEAL: return ["Egg McMuffin® Meal", "mcdonalds/DC_202007_0252_EVM_HB_EggMcMuffin_Coffee_Glass_832x472.jpeg"];
        case MenuItem.SAUSAGE_MCMUFFIN_WITH_EGG_MEAL: return ["Sausage McMuffin® with Egg Meal", "mcdonalds/DC_202008_3655_EVM_HB_SausageEggMcMuffin_Coffee_Glass_832x472.jpeg"];
        case MenuItem.SAUSAGE_BISCUIT_WITH_EGG_MEAL: return ["Sausage Biscuit with Egg Meal", "mcdonalds/DC_202007_0251_EVM_HB_SausageEggBiscuit_Coffee_Glass_832x472.jpeg"];
        case MenuItem.BACON_EGG_AND_CHEESE_BISCUIT_MEAL: return ["Bacon, Egg & Cheese Biscuit Meal", "mcdonalds/DC_202104_0250_EVM_HB_BaconEggCheeseBiscuit_Coffee_Glass_832x472.jpeg"];
        case MenuItem.BACON_EGG_AND_CHEESE_MCGRIDDLES_MEAL: return ["Bacon, Egg & Cheese McGriddles® Meal", "mcdonalds/DC_201908_9839_BEC_McGriddle_832x472.jpeg"];
        case MenuItem.SAUSAGE_EGG_AND_CHEESE_MCGRIDDLES_MEAL: return ["Sausage, Egg & Cheese McGriddles® Meal", "mcdonalds/DC_202008_9842_EVM_HB_SausageEggCheeseMcGriddle_Coffee_Glass_832x472.jpeg"];
        case MenuItem.SAUSAGE_MCGRIDDLES_MEAL: return ["Sausage McGriddles® Meal", "mcdonalds/DC_202211_6116_EVM_HB_SausageMcGriddle_Coffee_Glass_832x472.jpeg"];
        case MenuItem.SAUSAGE_BURRITO_MEAL: return ["Sausage Burrito Meal", "mcdonalds/DC_202008_5093_EVM_HB_2SausageBurrito_Coffee_Glass_832x472.jpeg"];
        case MenuItem.BIG_MAC: return ["Big Mac®", "mcdonalds/Header_BigMac_832x472.jpeg"];
        case MenuItem.QUARTER_POUNDER_WITH_CHEESE: return ["Quarter Pounder®* with Cheese", "mcdonalds/DC_202201_0007-005_QuarterPounderwithCheese_832x472.jpeg"];
        case MenuItem.DOUBLE_QUARTER_POUNDER_WITH_CHEESE: return ["Double Quarter Pounder®* with Cheese", "mcdonalds/202201_3426-005_DoubleQuarterPounderwithCheese_832x472.jpeg"];
        case MenuItem.QUARTER_POUNDER_WITH_CHEESE_DELUXE: return ["Quarter Pounder®* with Cheese Deluxe", "mcdonalds/DC_202201_4282_QuarterPounderCheeseDeluxe_Shredded_832x472.jpeg"];
        case MenuItem.MCDOUBLE: return ["McDouble®", "mcdonalds/Header_McDouble_832x472.jpeg"];
        case MenuItem.QUARTER_POUNDER_WITH_CHEESE_BACON: return ["Quarter Pounder®* with Cheese Bacon", "mcdonalds/DC_202201_4295-005_BaconQPC_832x472.jpeg"];
        case MenuItem.CHEESEBURGER: return ["Cheeseburger", "mcdonalds/Header_Cheeseburger_832x472.jpeg"];
        case MenuItem.DOUBLE_CHEESEBURGER: return ["Double Cheeseburger", "mcdonalds/Header_DoubleCheeseburger_832x472.jpeg"];
        case MenuItem.HAMBURGER_THE_CLASSIC_MCDONALDS_BURGER: return ["Hamburger: The Classic McDonald's Burger", "mcdonalds/DC_202103_6975_HamburgerHappyMeal_AppleSlices_WhiteMilkJug_Left_832x472.jpeg"];
        case MenuItem.MCCRISPY: return ["McCrispy™", "mcdonalds/DC_202002_6050_SmallFrenchFries_Standing_832x472.jpeg"];
        case MenuItem.DELUXE_MCCRISPY: return ["Deluxe McCrispy™", "mcdonalds/DC_202012_0370_DeluxeCrispyChicken_PotatoBun_832x472.jpeg"];
        case MenuItem.SPICY_MCCRISPY: return ["Spicy McCrispy™", "mcdonalds/DC_202012_0116_SpicyCrispyChicken_PotatoBun_832x472.jpeg"];
        case MenuItem.SPICY_DELUXE_MCCRISPY: return ["Spicy Deluxe McCrispy™", "mcdonalds/DC_202104_0100_DeluxeSpicyCrispyChickenSandwich_PotatoBun_832x472.jpeg"];
        case MenuItem.FILET_O_FISH: return ["Filet-O-Fish®", "mcdonalds/Header_FiletoFish_832x472.jpeg"];
        case MenuItem.MCCHICKEN: return ["McChicken®", "mcdonalds/Header_McChicken_832x472.jpeg"];
        case MenuItem.CHICKEN_MCNUGGETS: return ["Chicken McNuggets®", "mcdonalds/DC_202006_0483_4McNuggets_Stacked_832x472.jpeg"];
        case MenuItem.TEN_PIECE_CHICKEN_MCNUGGETS: return ["10 Piece Chicken McNuggets®", "mcdonalds/DC_202012_0383_CrispyChickenSandwich_PotatoBun_832x472.jpeg"];
        case MenuItem.WORLD_FAMOUS_FRIES: return ["World Famous Fries®", "mcdonalds/DC_202002_1500_Oatmeal_Fruit_832x472.jpeg"];
        case MenuItem.APPLE_SLICES: return ["Apple Slices", "mcdonalds/DC_202002_2794_AppleSlices_NoBag_832x472.jpeg"];
        case MenuItem.TANGY_BARBEQUE_SAUCE: return ["Tangy Barbeque Sauce", "mcdonalds/DC_Ingredient_Condiment_202203_00408-144__0900_TangyBBQ_832x472.jpeg"];
        case MenuItem.SPICY_BUFFALO_SAUCE: return ["Spicy Buffalo Sauce", "mcdonalds/DC_Ingredient_Condiment_202203_07812-045__9089_SpicyBuffalo_832x472.jpeg"];
        case MenuItem.CREAMY_RANCH_SAUCE: return ["Creamy Ranch Sauce", "mcdonalds/DC_Ingredient_Condiment_202203_02861-036__0922_CreamyRanch_832x472.jpeg"];
        case MenuItem.HONEY_MUSTARD_SAUCE: return ["Honey Mustard Sauce", "mcdonalds/DC_Ingredient_Condiment_202203_08731-024__9088_HoneyMustard_832x472.jpeg"];
        case MenuItem.HONEY: return ["Honey", "mcdonalds/DC_0902_00411-012_Honey_832x472.jpeg"];
        case MenuItem.SWEET_N_SOUR_SAUCE: return ["Sweet 'N Sour Sauce", "mcdonalds/DC_Ingredient_Condiment_202203_00409-120__0901_SweetNSour_832x472.jpeg"];
        case MenuItem.KETCHUP_PACKET: return ["Ketchup Packet", "mcdonalds/DC_Ingredient_Condiment_202203_02679-243__0912_Ketchup_832x472.jpeg"];
        case MenuItem.MUSTARD_PACKET: return ["Mustard Packet", "mcdonalds/DC_202208_5280_10McNuggets_Stacked_832x472.jpeg"];
        case MenuItem.MAYONNAISE_PACKET: return ["Mayonnaise Packet", "mcdonalds/Ingredient_Condiment_202203_11668-000__9008_Mayonnaise_832x472.jpeg"];
        case MenuItem.HAMBURGER_HAPPY_MEAL: return ["Hamburger Happy Meal®", "mcdonalds/Header_Hamburger_832x472.jpeg"];
        case MenuItem.FOUR_PIECE_CHICKEN_MCNUGGETS_HAPPY_MEAL: return ["4 Piece Chicken McNuggets® Happy Meal®", "mcdonalds/DC_202103_7780_6McNuggetsHappyMeal_AppleSlices_WhiteMilkJug_Left_832x472.jpeg"];
        case MenuItem.SIX_PIECE_CHICKEN_MCNUGGETS_HAPPY_MEAL: return ["6 Piece Chicken McNuggets® Happy Meal®", "mcdonalds/DC_202103_7002_4McNuggetsHappyMeal_AppleSlices_WhiteMilkJug_Left_832x472.jpeg"];
        case MenuItem.CARAMEL_MACCHIATO: return ["Caramel Macchiato", "mcdonalds/DC_201906_2804_MediumCaramelMacchiato_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.CAPPUCCINO: return ["Cappuccino", "mcdonalds/DC_201906_2692_MediumCappuccino_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.CARAMEL_CAPPUCCINO: return ["Caramel Cappuccino", "mcdonalds/DC_201906_1517_MediumCaramelCappuccino_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.FRENCH_VANILLA_CAPPUCCINO: return ["French Vanilla Cappuccino", "mcdonalds/DC_201906_1372_MediumVanillaCappuccino_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.MOCHA_LATTE: return ["Mocha Latte", "mcdonalds/DC_201906_2731_MediumMocha_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.AMERICANO: return ["Americano", "mcdonalds/DC_201906_1318_MediumAmericano_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.PREMIUM_ROAST_COFFEE: return ["Premium Roast Coffee", "mcdonalds/DC_201906_0321_MediumCoffee_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.ICED_CARAMEL_MACCHIATO: return ["Iced Caramel Macchiato", "mcdonalds/DC_201906_2743_MediumIcedCaramelMacchiato_Glass_A1_832x472.jpeg"];
        case MenuItem.ICED_MOCHA: return ["Iced Mocha", "mcdonalds/DC_201906_7659_MediumIcedMocha_Glass_A1_832x472.jpeg"];
        case MenuItem.ICED_COFFEE: return ["Iced Coffee", "mcdonalds/DC_201906_1212_MediumIcedCoffee_Glass_A1_832x472.jpeg"];
        case MenuItem.ICED_CARAMEL_COFFEE: return ["Iced Caramel Coffee", "mcdonalds/DC_201906_1207_MediumIcedCaramelCoffee_Glass_A1_832x472.jpeg"];
        case MenuItem.ICED_FRENCH_VANILLA_COFFEE: return ["Iced French Vanilla Coffee", "mcdonalds/DC_201906_1216_MediumIcedFrenchVanillaCoffee_Glass_A1_832x472.jpeg"];
        case MenuItem.LATTE: return ["Latte", "mcdonalds/DC_201906_2698_MediumLatte_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.ICED_LATTE: return ["Iced Latte", "mcdonalds/DC_201906_7606_MediumIcedLatte_Glass_A1_832x472.jpeg"];
        case MenuItem.CARAMEL_LATTE: return ["Caramel Latte", "mcdonalds/DC_201906_1324_MediumCaramelLatte_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.ICED_CARAMEL_LATTE: return ["Iced Caramel Latte", "mcdonalds/DC_201906_0379_MediumIcedCaramelLatte_Glass_A1_832x472.jpeg"];
        case MenuItem.FRENCH_VANILLA_LATTE: return ["French Vanilla Latte", "mcdonalds/DC_201906_1331_MediumFrenchVanillaLatte_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.ICED_FRENCH_VANILLA_LATTE: return ["Iced French Vanilla Latte", "mcdonalds/DC_201906_0187_MediumIcedFrenchVanillaLatte_Glass_A1_832x472.jpeg"];
        case MenuItem.CARAMEL_FRAPPE: return ["Caramel Frappé", "mcdonalds/DC_201906_2842_MediumCaramelFrappe_Glass_A1_832x472.jpeg"];
        case MenuItem.MOCHA_FRAPPE: return ["Mocha Frappé", "mcdonalds/DC_201906_3491_MediumMochaFrappe_Glass_A1_832x472.jpeg"];
        case MenuItem.MCFLURRY_WITH_OREO_COOKIES: return ["McFlurry® with OREO® Cookies", "mcdonalds/DC_202002_3832_OREOMcFlurry_832x472.jpeg"];
        case MenuItem.MCFLURRY_WITH_M_AND_MS_CANDIES: return ["McFlurry® with M&M'S® Candies", "mcdonalds/DC_202002_3830_MandMMcFlurry_832x472.jpeg"];
        case MenuItem.VANILLA_CONE: return ["Vanilla Cone", "mcdonalds/DC_202106_0336_LargeVanillaCone_832x472.jpeg"];
        case MenuItem.CHOCOLATE_SHAKE: return ["Chocolate Shake", "mcdonalds/DC_201907_1509_MediumChocolateShake_Glass_A1_832x472.jpeg"];
        case MenuItem.VANILLA_SHAKE: return ["Vanilla Shake", "mcdonalds/DC_201907_1598_MediumVanillaShake_Glass_A1_832x472.jpeg"];
        case MenuItem.STRAWBERRY_SHAKE: return ["Strawberry Shake", "mcdonalds/DC_201907_1513_MediumStrawberryShake_Glass_A1_832x472.jpeg"];
        case MenuItem.HOT_FUDGE_SUNDAE: return ["Hot Fudge Sundae", "mcdonalds/DC_201907_0337_HotFudgeSundae_832x472.jpeg"];
        case MenuItem.HOT_CARAMEL_SUNDAE: return ["Hot Caramel Sundae", "mcdonalds/DC_201907_0345_CaramelSundae_832x472.jpeg"];
        case MenuItem.BAKED_APPLE_PIE: return ["Baked Apple Pie", "mcdonalds/DC_202004_0706_BakedApplePie_Broken_832x472.jpeg"];
        case MenuItem.CHOCOLATE_CHIP_COOKIE: return ["Chocolate Chip Cookie", "mcdonalds/DC_202004_1852_ChocolateChipCookie_Broken_832x472.jpeg"];
        case MenuItem.COCA_COLA: return ["Coca-Cola®", "mcdonalds/DC_202112_0521_MediumCoke_Glass_832x472.jpeg"];
        case MenuItem.SPRITE: return ["Sprite®", "mcdonalds/Header_MediumSprite_Glass_832x472.jpeg"];
        case MenuItem.DR_PEPPER: return ["Dr Pepper®", "mcdonalds/DC_201905_0421_MediumDrPepper_Glass_832x472.jpeg"];
        case MenuItem.FANTA_ORANGE: return ["Fanta® Orange", "mcdonalds/Header_MediumFantaOrange_Glass_832x472.jpeg"];
        case MenuItem.DIET_COKE: return ["Diet Coke®", "mcdonalds/DC_202112_0652_MediumDietCoke_Glass_2_832x472.jpeg"];
        case MenuItem.HI_C_ORANGE_LAVABURST: return ["Hi-C® Orange Lavaburst®", "mcdonalds/DC_202012_0621_MediumHi-COrange_832x472.jpeg"];
        case MenuItem.FROZEN_FANTA_BLUE_RASPBERRY: return ["Frozen Fanta® Blue Raspberry", "mcdonalds/Header_MediumFrozenFantaBlueRaspberry_Glass_832x472-1.jpeg"];
        case MenuItem.FROZEN_COCACOLA_CLASSIC: return ["Frozen Coca-Cola® Classic", "mcdonalds/Header_MediumFrozenCocaColaClassic_Glass_832x472-1.jpeg"];
        case MenuItem.STRAWBERRY_BANANA_SMOOTHIE: return ["Strawberry Banana Smoothie", "mcdonalds/DC_201906_2725_MediumStrawberryBananaSmoothie_Glass_A1_832x472.jpeg"];
        case MenuItem.MANGO_PINEAPPLE_SMOOTHIE: return ["Mango Pineapple Smoothie", "mcdonalds/DC_201906_2790_MediumMangoPineappleSmoothie_Glass_A1_832x472.jpeg"];
        case MenuItem.LEMONADE: return ["Lemonade", "mcdonalds/McDonaldsLemonade_832x472.jpeg"];
        case MenuItem.SWEET_TEA: return ["Sweet Tea", "mcdonalds/DC_202105_3429_SweetTea_Glass_A1_832x472.jpeg"];
        case MenuItem.UNSWEETENED_ICED_TEA: return ["Unsweetened Iced Tea", "mcdonalds/DC_201909_9449_UnsweetTea_Glass_A1_832x472.jpeg"];
        case MenuItem.HOT_TEA: return ["Hot Tea", "mcdonalds/DC_201906_0573_MediumHotTea_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.MINUTE_MAID_PREMIUM_ORANGE_JUICE: return ["Minute Maid® Premium Orange Juice", "mcdonalds/Header_MediumMinuteMaidPremiumOrangeJuice_832x472.jpeg"];
        case MenuItem.KIDS_APPLEY_EVER_AFTER_ORGANIC_JUICE_DRINK: return ["Kids Appley Ever After® Organic Juice Drink", "mcdonalds/DC_202008_0322_HonestKidsAppleJuice_832x472.jpeg"];
        case MenuItem.MILK: return ["Milk", "mcdonalds/DC_202103_0066_MilkJug_832x472.jpeg"];
        case MenuItem.CHOCOLATE_MILK: return ["Chocolate Milk", "mcdonalds/DC_202103_3179_ReducedSugarLowFatChocolateMilk_832x472.jpeg"];
        case MenuItem.HOT_CHOCOLATE: return ["Hot Chocolate", "mcdonalds/DC_201906_1697_MediumPremiumHotChocolate_Glass_A1_HL_832x472.jpeg"];
        case MenuItem.WATER: return ["Water", "mcdonalds/DC_202106_5474_DasaniBottledWater_832x472.jpeg"];
    }
};


interface LatestConversationUpdate {
    nmeals: number;
    nwordsTranscribed: number;
    nwordsSpoken: number;
    latestMenuItemOrdered: string;
    latestConversationId: string;
    latestConversationStatus: string;
    latestTranscript: string;
}

interface LatestTranscriptUpdate {
    transcript: string;
    id: string;
}

export class Backend {
    public numberOfMealsOrdered: number;
    public numberOfWordsTranscribed: number;
    public numberOfWordsSpoken: number;
    public latestMenuItemOrdered: MenuItem | undefined;
    public latestTranscript: string;
    public latestConversationID: string;
    public latestConversationStatus: string;

    private apiHostname = "https://deepslice-api-mcdonalds.deepgram.com";

    constructor() {
        this.latestTranscript = "";
        this.numberOfMealsOrdered = 0;
        this.numberOfWordsTranscribed = 0;
        this.numberOfWordsSpoken = 0;
        this.latestMenuItemOrdered = MenuItem.BIG_MAC;
        this.latestConversationID = "";
        this.latestConversationStatus = "";
    }

    public updateDashboard = async (): Promise<void> => {
        try {
            const response = (await axios.get(
                this.apiHostname + "/dashboard",
                {
                    headers: {
                        'accept': 'application/json',
                        'accept-language': 'en-US,en;q=0.9',
                        'x-api-key': 'fj958hvcakfj4jax',
                    },
                }
            )).data as LatestConversationUpdate;
            if (response !== undefined) {
                this.numberOfMealsOrdered = response.nmeals;
                this.numberOfWordsTranscribed = response.nwordsTranscribed;
                this.numberOfWordsSpoken = response.nwordsSpoken;
                this.latestMenuItemOrdered = toMenuItem(response.latestMenuItemOrdered);
                this.latestTranscript = response.latestTranscript;
                this.latestConversationID = response.latestConversationId;
                this.latestConversationStatus = response.latestConversationStatus;
            }
            return;
        } catch (error) {
            console.error('Error fetching latest data:', error);
            throw error;
        }
    }

    public updateLatestTranscript = async (conversationId: string): Promise<LatestTranscriptUpdate> => {
        try {
            const response = await axios.get(
                this.apiHostname = `/latest-transcript/${conversationId}`,
                {
                    headers: {
                        'accept': 'application/json',
                        'accept-language': 'en-US,en;q=0.9',
                        'x-api-key': 'fj958hvcakfj4jax',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching latest data:', error);
            throw error;
        }
    }
}


// Once every 5 seconds, look for new calls that were/are being made to Vapi
// Once every 0.5 seconds, pull the latest call's transcript if the call is in-progress
