import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Backend, MenuItem, menuItemToNameAndImage } from './services2';
import './App.css';

function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const App: React.FC = () => {
  const conversationRef = useRef<HTMLDivElement>(null);
  const [latestMenuItemOrdered, setLatestMenuItemOrdered] = useState<[string, string] | undefined>(["Big Mac®", "/mcdonalds/big-mac.png"]);
  const backend = useMemo(() => new Backend(), []);  // creates the object one time
  const [numberOfMealsOrdered, setNumberOfPizzasOrderd] = useState<number>(0);
  const [numberOfWordsTranscribed, setNumberOfWordsTranscribed] = useState<number>(0);
  const [numberOfWordsSpoken, setNumberOfWordsSpoken] = useState<number>(0);
  const [latestTranscript, setLatestTranscript] = useState<string>("");
  const [latestConversationId, setLatestConversationId] = useState<string>("");
  const [latestConversationStatus, setLatestConversationStatus] = useState<string>("");
  const [favoriteItems, setFavoriteItems] = useState<MenuItem[]>([
    MenuItem.SPICY_MCCRISPY,
    MenuItem.BIG_MAC,
    MenuItem.CHICKEN_MCNUGGETS,
    MenuItem.QUARTER_POUNDER_WITH_CHEESE,
    MenuItem.ICED_COFFEE,
    MenuItem.EGG_MCMUFFIN,
    MenuItem.SAUSAGE_BURRITO,
    MenuItem.CHEESEBURGER,
    MenuItem.HOT_FUDGE_SUNDAE,
  ]);

  // Set up an interval that executes the backend code
  useEffect(() => {
    const updateDashboard = async () => {
      try {
        await backend.updateDashboard();
        setNumberOfPizzasOrderd(backend.numberOfMealsOrdered);
        setNumberOfWordsTranscribed(backend.numberOfWordsTranscribed);
        setNumberOfWordsSpoken(backend.numberOfWordsSpoken);
        setLatestTranscript(backend.latestTranscript);
        setLatestConversationId(backend.latestConversationID);
        setLatestConversationStatus(backend.latestConversationStatus);
        setLatestMenuItemOrdered(backend.latestMenuItemOrdered ? menuItemToNameAndImage(backend.latestMenuItemOrdered) : undefined);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    updateDashboard();
    const intervalId = setInterval(updateDashboard, 5000); // Run every X seconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const updateInProgressTranscript = async () => {
      if (latestConversationStatus === "in-progress") {
        try {
          const update = await backend.updateLatestTranscript(latestConversationId);
          if (update !== undefined && update.id === latestConversationId && update.transcript !== null) {
            setLatestTranscript(update.transcript);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    }
    updateInProgressTranscript();
    const intervalId = setInterval(updateInProgressTranscript, 500); // Run every X seconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (conversationRef.current) {
        let scrollSpeed = 1;  // set to 1 to scroll slowly or a very high number to auto-scroll to the bottom
        if (latestConversationStatus === "in-progress") {
          scrollSpeed = 999999999999999;
        }
        conversationRef.current.scrollTop += scrollSpeed;
        if (conversationRef.current.scrollTop === conversationRef.current.scrollHeight) {
          conversationRef.current.scrollTop = 0;
        }
      }
    }, 50); // Adjust the scroll speed (interval) as needed

    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div className="outer">
      <div className="container">
        <div className="logo-header">
          <div className="logo-center">
            <img src="/mcdonalds/mcdonalds-logo.png" className="mcdonalds-logo"></img>
            <span className="text-logo">McDonald's Menu</span>
          </div>
        </div>

        <div className="columns-wrapper">
          {/* First column */}
          <div className="column1">
            <div className="top-row">
              <div className="inner-column1 foreground-container">
                <div className="red-bar"></div>
                <span className="text text-header-size block">
                  Latest item ordered
                </span>
                <img src={latestMenuItemOrdered ? latestMenuItemOrdered[1] : "/mcdonalds/big-mac.png"} alt="Pizza" className="pizza-image block" />
                <span className="text text-win-pizza-size heavy-weight block">
                  Order your meal now!<br />
                </span>
                <span className="text text-phone-number-size block">
                  Call +1 (415) 634 2944
                </span>
              </div>

              <div className="inner-column2 foreground-container">
                <div className="red-bar"></div>
                <span className="text favorites-text text-header-size block">Featured Favorites</span>
                <div className="grid">
                  {favoriteItems.map((item: MenuItem) => (
                    <div className="menu-item" key={(menuItemToNameAndImage(item) || [undefined])[0]}>
                      <img className="topping-image" src={(menuItemToNameAndImage(item) || [undefined])[1]}></img>
                      <span className="text text-menu-item">{(menuItemToNameAndImage(item) || [undefined])[0]}</span>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>

            <div className="bottom-row foreground-container">
              <div className="footer">
                <div className="footer-item footer-item-split">
                  <div className="footer-number">{numberWithCommas(numberOfMealsOrdered)}</div>
                  <div className="text text-body-size">Meals Ordered</div>
                </div>
                <div className="footer-item footer-item-split">
                  <div className="footer-number">{numberWithCommas(numberOfWordsTranscribed + numberOfWordsSpoken)}</div>
                  <div className="text text-body-size">Words Transcribed</div>
                </div>
                <div className="footer-item">
                  <div className="footer-number">{numberWithCommas(numberOfWordsSpoken)}</div>
                  <div className="text text-body-size">Words Spoken</div>
                </div>
              </div>
            </div>
          </div>

          <div className="column2 foreground-container">
            <div className="red-bar red-bar-custom-width"></div>
            <div className="text text-center text-header-size">Latest call</div>
            <div className="call" ref={conversationRef}>
              <span className="conversationText">
                {latestTranscript}
              </span>
            </div>
          </div>
        </div>

      </div>
      <div className="bottom-yellow-bar"></div>
    </div>
  );
};

export default App;
